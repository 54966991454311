<template>
    <OInputMask v-if="mask" 
        ref="maskInputRef"
        :mask="mask"
        :modelValue="modelValue"
        type="search" 
        :autoClear="false"
        class="form-control rounded-0" 
        normalizeOnBlur
        @keydown.enter="doSearch($event)"
        @input="onInput($event)"
        :placeholder="$t('Search...')" />
    <input v-else
        type ="search"
        :value="modelValue"
        class="form-control rounded-0" 
        :placeholder="$t('Search...')"
        @keyup="doSearch($event)" 
        @input="onInput($event)" />
</template>
<script setup>
import OInputMask from './Inputs.InputMask.vue';
import { ref } from 'vue';

const props = defineProps({
    modelValue: null,
    mask: {
        type: String
    },
    debounce: {
        type: Boolean,
        default: false
    }
});
const debouncedSearch = ref(null);
let prevSearchString = props.modelValue;

const emit = defineEmits(['onSearch', 'update:modelValue']);
const maskInputRef = ref(null);

function checkForMaskCompletion() {
    if (props.mask) {
        maskInputRef.value?.$el?.focus();
        if (!maskInputRef.value?.isFilled()) {
            prevSearchString = '';
        }
    }
}
const onInput = (e) => {
    if (!e.inputType && prevSearchString) {
        // Event doesn't have input type, that means user clicked the clear button
        // If prevSearchString exists we should clear the value
        prevSearchString = e.target.value;
        checkForMaskCompletion();
        search();
    } else if (e.inputType && prevSearchString && props.debounce) {
        // Event has inputType, that means this is a user typed in value
        prevSearchString = e.target.value;
        checkForMaskCompletion();
        clearTimeout(debouncedSearch.value);
        debouncedSearch.value = setTimeout(() => {
            search();
        }, 300);
    }
}
const doSearch = (e) => {
    prevSearchString = e.target.value//searchInput.value;
    if (e.keyCode == 13) {
        checkForMaskCompletion();
        search();
    }

}

function search() {
    emit("onSearch", prevSearchString, true);
    emit("update:modelValue", prevSearchString);
}

</script>